/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF,

 } from '@react-three/drei'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/pen/model.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
<mesh geometry={nodes.Cylinder026.geometry} material={nodes.Cylinder026.material} scale={0.7} >
<mesh geometry={nodes.Cylinder021.geometry} material={nodes.Cylinder021.material} position={[0, -1.71, -0.01,]} />
<mesh geometry={nodes.Cylinder023.geometry} material={nodes.Cylinder023.material} position={[0, -1.3, -0.01,]} />
<mesh geometry={nodes.Cylinder024.geometry} material={materials['PenGlossyPart1.001']} position={[0.07, 0.64, 0,]} />
<mesh geometry={nodes.Cylinder025.geometry} material={nodes.Cylinder025.material} position={[0, 1.06, 0,]} />
</mesh>

    </group>
  )
}

useGLTF.preload('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/pen/model.gltf')
